import {
  ref,
  computed,
  watch,
  onMounted,
} from '@vue/composition-api'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@core/utils/useSelectOptions'
import store from '@/store'

export default function useFilters() {
  const { userData, encrypt, decrypt } = useCryptoJs()
  const {
    configOrganization,
    configFacility,
  } = useSelectOptions()

  const refreshTimer = ref(null)
  const filters = ref(null)
  const initFilters = {
    'views-dashboard': {
      fields: {
        organization: null,
        facility: null,
        custom: null,
        dateFrom: null,
        dateTo: null,
      },
    },
    'views-user-list': {
      fields: {
        role: null,
        status: null,
        search: null,
      },
    },
    'views-role-list': {
      fields: {
        search: null,
      },
    },
    'views-payment-list': {
      fields: {
        status: null,
        dateFrom: null,
        dateTo: null,
        search: null,
      },
    },
    'views-basic-list': {
      fields: {
        search: null,
      },
    },
    'views-shipping-list': {
      fields: {
        search: null,
      },
    },
    'views-project-list': {
      fields: {
        search: null,
      },
    },
    'views-client-list': {
      fields: {
        search: null,
      },
    },
    'views-vehicle-list': {
      fields: {
        search: null,
      },
    },
    'views-driver-list': {
      fields: {
        search: null,
      },
    },
    'views-mix-type-list': {
      fields: {
        search: null,
      },
    },
    'views-construction-site-list': {
      fields: {
        search: null,
      },
    },
    'views-product-list': {
      fields: {
        search: null,
      },
    },
  }

  const configFilters = computed({
    get: () => {
      if (store.state.app.configFilters) {
        const decr = decrypt(JSON.parse(store.state.app.configFilters))

        filters.value = JSON.parse(decr)

        return filters.value
      }

      return filters.value
    },
    set: value => {
      if (value) {
        filters.value = value
        const encr = encrypt(value)
        store.commit('app/UPDATE_FILTERS', JSON.stringify(encr))
        localStorage.setItem('config-filters', JSON.stringify(encr))
      }
    },
  })

  const updateFilters = (name, fields) => {
    filters.value[name].fields = fields
    configFilters.value = filters.value
  }

  const updateFilter = (name, field, value) => {
    filters.value[name].fields[field] = value
    configFilters.value = filters.value
  }

  const updateFilterSubmodule = (name, submodule, field, value) => {
    filters.value[name][submodule].fields[field] = value
    configFilters.value = filters.value
  }

  const getFilterByModule = name => {
    if (configFilters.value) return configFilters.value[name].fields

    return null
  }

  const getFilterBySubmodule = (name, submodule) => {
    if (configFilters.value) return configFilters.value[name][submodule].fields

    return null
  }

  const getFilterByField = (name, field) => {
    if (configFilters.value) return configFilters.value[name].fields[field]

    return null
  }

  const resetFilterValues = () => {
    filters.value = initFilters
    configFilters.value = initFilters
  }

  watch([configOrganization, configFacility], async () => {
    clearTimeout(refreshTimer.value)
    refreshTimer.value = setTimeout(async () => {
      resetFilterValues()
    }, 500)
  })

  onMounted(() => {
    if (!configFilters.value) {
      filters.value = initFilters
      configFilters.value = filters.value
    }
  })

  return {
    userData,
    filters,

    configFilters,

    updateFilters,
    updateFilterSubmodule,
    updateFilter,
    getFilterByModule,
    getFilterBySubmodule,
    getFilterByField,
    resetFilterValues,
  }
}
